<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="[16,6]">
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Whonet Org Code</label>
            <input nz-input [(ngModel)]="dataItem.whoneT_ORG_CODE" [disabled]="isView">

        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Organism</label>
            <input nz-input [(ngModel)]="dataItem.organism" [disabled]="isView">

        </div>       
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Taxonomic Status</label>
            <input nz-input [(ngModel)]="dataItem.taxonomiC_STATUS" [disabled]="isView">
        </div>

        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Common</label>
            <input nz-input [(ngModel)]="dataItem.common" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Organism Type</label>
            <input nz-input [(ngModel)]="dataItem.organisM_TYPE" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Anaerobe</label>
            <input nz-input [(ngModel)]="dataItem.anaerobe" [disabled]="isView">
    
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Morphology</label>
            <input nz-input [(ngModel)]="dataItem.morphology" [disabled]="isView">
    
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Subkingdom Code</label>
            <input nz-input [(ngModel)]="dataItem.subkingdoM_CODE" [disabled]="isView">
        </div>       
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Family Code</label>
            <input nz-input [(ngModel)]="dataItem.familY_CODE" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Genus Group</label>
            <input nz-input [(ngModel)]="dataItem.genuS_GROUP" [disabled]="isView">
    
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Genus Code</label>
            <input nz-input [(ngModel)]="dataItem.genuS_CODE" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Species Group</label>
            <input nz-input [(ngModel)]="dataItem.specieS_GROUP" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Serovar Group</label>
            <input nz-input [(ngModel)]="dataItem.serovaR_GROUP" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Msf Grp Clin</label>
            <input nz-input [(ngModel)]="dataItem.msF_GRP_CLIN" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Sct Code</label>
            <input nz-input [(ngModel)]="dataItem.scT_CODE" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Sct Text</label>
            <input nz-input [(ngModel)]="dataItem.scT_TEXT" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Dwc Taxon Id</label>
            <input nz-input [(ngModel)]="dataItem.dwC_TAXON_ID" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Dwc Taxonomic Status</label>
            <input nz-input [(ngModel)]="dataItem.dwC_TAXONOMIC_STATUS" [disabled]="isView">
         
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Gbif Taxon Id</label>
            <input nz-input [(ngModel)]="dataItem.gbiF_TAXON_ID" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Gbif Dataset Id</label>
            <input nz-input [(ngModel)]="dataItem.gbiF_DATASET_ID" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Gbif Taxonomic Status</label>
            <input nz-input [(ngModel)]="dataItem.gbiF_TAXONOMIC_STATUS" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Kingdom</label>
            <input nz-input [(ngModel)]="dataItem.kingdom" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Phylum</label>
            <input nz-input [(ngModel)]="dataItem.phylum" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Organism Class</label>
            <input nz-input [(ngModel)]="dataItem.class" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Order</label>
            <input nz-input [(ngModel)]="dataItem.organism_order" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Family</label>
            <input nz-input [(ngModel)]="dataItem.family" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Genus</label>
            <input nz-input [(ngModel)]="dataItem.genus" [disabled]="isView">
        </div>
    </div>
   

</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon
            nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>