import { Component, Injector, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { PhanNhomChinhDto, PhanNhomChinhServiceProxy, ComboBoxEnumCode } from '@shared/service-proxies/service-proxies';
import { ModalComponentBase } from '@shared/common/modal-component-base';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import * as $ from 'jquery';
import { Hotkey } from 'angular2-hotkeys';

@Component({
    templateUrl: './phan-nhom-chinh-edit-modal.component.html',
})
export class PhanNhomChinhEditModalComponent extends ModalComponentBase implements OnInit {
    formId = 'formValidatePhanNhomChinh';

    @Input() dataItem: PhanNhomChinhDto;

    ComboBoxEnumCode = ComboBoxEnumCode;

    saving = false;

    constructor(injector: Injector, private _PhanNhomChinhService: PhanNhomChinhServiceProxy) {
        super(injector);
    }

    initHotkeys() {
        this.hotkeys = [];

        this.hotkeys.push(
            new Hotkey(
                'f10',
                (): boolean => {
                    this.save();
                    return false;
                },
                ['INPUT', 'TEXTAREA', 'SELECT'],
            ),
        );
    }

    ngOnInit(): void {
        //F5 createHotkeys
        this.createHotkeys();
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } else {
            this.clearForm();
            this.dataItem.isMaTuSinh = true
        }
    }

    getInfoById(id: number): void {
        abp.ui.setBusy();
        this._PhanNhomChinhService
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                let data = response;
                this.dataItem = data;
            });
    }

    save(): void {
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error('Bạn cần nhập đủ dữ liệu các trường có dấu * đỏ !!');
            return;
        }
        abp.ui.setBusy();
        this._PhanNhomChinhService
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(this.l('HasError'));
                }
            });
    }

    clearForm() {
        this.dataItem = Object.assign(new PhanNhomChinhDto(), {
            isDungMaHeThong: true,
        });
    }

    //#region UX_JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    //#endregion
}
