import { Injectable, Injector } from '@angular/core';
import { PhuongThucPhatHienSuCoDto } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PhuongThucPhatHienSuCoEditModalComponent } from '../modal/phuong-thuc-phat-hien-su-co-edit-modal.component';





@Injectable(
    {
        providedIn:'root'
    }
)export class PhuongThucPhatHienSuCoUXService {
    constructor(private modalService: NzModalService) { }

    public openCreateOrEditModal(option: { dataItem: PhuongThucPhatHienSuCoDto; callBack?: any }): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = option.dataItem?.id > 0 ? 'Cập nhật ' : 'Thêm mới ';
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: PhuongThucPhatHienSuCoEditModalComponent,
            nzComponentParams: {
                dataItem: Object.assign(new PhuongThucPhatHienSuCoDto(), { ...option.dataItem }),
            },
            nzMaskClosable: false,
            nzWidth: '50%',
        });

        modal.afterClose.subscribe((result: PhuongThucPhatHienSuCoDto) => {
            option.callBack(result);
        });
    }

    // public openSearchListModal(option: {
    //     listOfSelectedValue: number[];
    //     title?: string;
    //     isMulti?: boolean;
    //     callBack: any;
    // }): void {
    //     let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
    //     let sTitle = option.title || 'Danh sách ';
    //     const modal = this.modalService.create({
    //         nzTitle: sTitle + icon,
    //         nzContent: PhuongThucPhatHienSuCoSelectSearchModalComponent,
    //         nzComponentParams: {
    //             listOfSelectedValue: [...option.listOfSelectedValue],
    //             isMulti: option.isMulti,
    //         },
    //         nzFooter: null,
    //         nzMaskClosable: false,
    //         nzWidth: "60%"
    //     });

    //     modal.afterClose.subscribe((result: PhuongThucPhatHienSuCoDto[]) => {
    //         if (result != null && result.length > 0) {
    //             option.callBack(result);
    //         }
    //     });
    // }
}
