<ng-content select="[searchMain]"></ng-content>
<div style="margin-bottom: 5px;" *ngIf="showAdvancedSearch === true">
    <ng-content  select="[advancedSearch]" ></ng-content>
</div>
<div nz-row>
    <ng-content select="[colLeftSearch]"></ng-content>
    <div nz-col nzXs="24" nzMd="{{isOnlySearch?24:widthColSeach}}" *ngIf="!isTypeButtonSearch"
        class="txt-button-search">
        <nz-form-label *ngIf="isShowTitleSearch">{{titleInputSearch}}</nz-form-label>
        <nz-form-control>
            <nz-input-group nzSearch [nzAddOnBefore]="prefixIconButton" [nzAddOnAfter]="suffixIconButton">
                <ng-content select=".txtSearch"></ng-content>
                <ng-content select=".txtSearchPlus"></ng-content>
            </nz-input-group>
        </nz-form-control>

        <ng-template #prefixIconButton>
            <a title="Tìm kiếm nâng cao" (click)="clickShowHideAdvancedSearch()">
                <i nz-icon nzType="filter" nzTheme="outline"></i>
            </a>
        </ng-template>

        <ng-template #suffixIconButton>
            <button nz-button nzType="primary" (click)="search()" title="{{'_TimKiem' | localize}}">
                {{'_TimKiem' | localize}}<i nz-icon nzType="search" nzTheme="outline"></i>
            </button>
            <button nz-button nzType="primary" (click)="refresh()" title="Làm mới">
                <i nz-icon nzType="sync" nzTheme="outline"></i>
            </button>
            <button *ngIf="hasAdvancedSearch" nz-button nzType="primary" (click)="clickShowHideAdvancedSearch()" title="Mở rộng">
                <i nz-icon [nzType]="showAdvancedSearch?'up':'down'" nzTheme="outline"></i>
            </button>
        </ng-template>
    </div>

    <div nz-col nzXs="24" nzSm="24" *ngIf="isTypeButtonSearch">
        <div style="width:100%; text-align:center;">
            <button nz-button nzType="primary" (click)="search()" title="{{'_TimKiem' | localize}}">
                <i nz-icon nzType="search" nzTheme="outline"></i> {{'_TimKiem' | localize}}
            </button>
            <button nz-button nzType="default" (click)="refresh()" title="Làm mới">
                <i nz-icon nzType="sync" nzTheme="outline"></i> Làm mới
            </button>
            <button nz-button nzType="default" title="Tìm kiếm nâng cao" (click)="clickShowHideAdvancedSearch()">
                <i nz-icon nzType="filter" nzTheme="outline"></i> Mở rộng
            </button>
        </div>
    </div>
</div>

