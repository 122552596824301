<div class="alain-default__header-logo">
    <a target="_blank" class="alain-default__header-logo-link">
        <img class="alain-default__header-logo-expanded" [src]="expandedLog" alt="{{settings.app.name}}" style="max-height:40px;" />
        <img class="alain-default__header-logo-collapsed" [src]="collapsedLogo" alt="{{settings.app.name}}" style="max-height:40px;" />
    </a>
</div>
<div class="alain-default__nav-wrap">
    <ul class="alain-default__nav">
        <li>
            <a class="mobile-menu-toggle" (click)="toggleCollapsedSidebar()">
                <i nz-icon nzType="menu-fold" nzTheme="outline"></i>
            </a>
        </li>
        <li style="font-weight: bold; text-transform: uppercase" class="hidden-mobile">
            <h1 class="title-he-thong">{{'_TieuDeHeThong' |localize}}</h1>
        </li>
    </ul>
    <ul class="alain-default__nav">
    </ul>
    <ul class="alain-default__nav">
        <!--<li *ngIf="isImpersonatedLogin">
            <button (click)="backToMyAccount()" nz-button nzType="link" title="Quay lại tài khoản chính">
                <i nz-icon nzType="api" nzTheme="outline"></i>
                Hệ thống thông tin quản lý hoạt động khám bệnh, chữa bệnh
            </button>
        </li>-->
        <li class="pr-2" *ngIf="appSession.user?.coSoId>0">
            {{appSession.user?.tenCoSo}}
        </li>
        <li>
            <language-switch></language-switch>
        </li>
        <li>
            <header-user style="float: left;"></header-user>
        </li>
    </ul>
</div>
