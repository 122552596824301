import { Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboServiceProxy, CoSoComboInputDto, CoSoDto } from '@shared/service-proxies/service-proxies';
import _ from 'lodash';
import { debounceTime, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import { CoSoUXService } from '@app/pages/quan-ly-co-so/danh-sach-co-so/services/co-so-ux.service';
import { AppUtilityService } from '../../../common/custom/utility.service';
import { Subject, Subscription } from 'rxjs';
import { NzSelectModeType } from 'ng-zorro-antd/select';

@Component({
    selector: 'co-so-combo',
    template: ` <div class="ora-combo">
        <nz-select
                    nzShowSearch
                    nzServerSearch
                    [nzMaxTagCount]="maxTagCount"
                    [nzMaxTagPlaceholder]="maxTagPlaceholderTmpl"
                    [nzAllowClear]="nzAllowClear"
                    nzPlaceHolder="{{ placeHolders }}"
                    [nzMode]="nzMode"
                    [nzLoading]="loading"
                    [(ngModel)]="_value"
                    [nzDisabled]="_isDisabled"
                    (nzFocus)="(onFocus)"
                    style="width:100%"
                    (ngModelChange)="onChangeValue($event)"
                    (nzOnSearch)="search$.next($event)"
                    [nzDropdownClassName]="'oda-select-dropdown'"
                    [nzDropdownRender]="renderTemplate"
                    [nzSuffixIcon]="prefixTemplateUser"
                >
                    <nz-option *ngFor="let item of optionList" [nzLabel]="item.displayText" [nzValue]="item.value"> </nz-option>

                    <ng-template #renderTemplate>
                        <hr *ngIf="isAdd || isSearchAdvance" />
                        <div *ngIf="isSearchAdvance">
                            <a nz-button nzType="link" (click)="searchAdvance()"
                                ><i nz-icon nzType="search" nzTheme="outline"></i> Tìm kiếm</a
                            >
                        </div>
                        <div *ngIf="isAdd">
                            <a nz-button nzType="link" (click)="openCreateOrEditModal(0)"
                                ><i nz-icon nzType="plus-circle" nzTheme="outline"></i> Thêm mới</a
                            >
                        </div>
                    </ng-template>

                    <ng-template #prefixTemplateUser><i nz-icon nzType="down" nzTheme="outline"></i></ng-template>
                </nz-select>
                <button nz-button nzType="text" (click)="openCreateOrEditModal(_value)" *ngIf="isUpdate && _value && !disabled">
                    <i nz-icon nzType="edit"></i>
                </button>
                <ng-template #maxTagPlaceholderTmpl let-selectedList>+ {{ selectedList.length }} </ng-template>
    </div>`,
    styles: [
        `
            .ora-combo {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
            }

            .ora-combo nz-select {
                width: 100%;
            }

            .ora-close {
                cursor: pointer;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CoSoComboComponent),
            multi: true,
        },
    ],
})
export class CoSoComboComponent  implements OnInit, OnChanges, ControlValueAccessor {
    @Input() placeHolders?: string = '- Chọn (Nhập để tìm kiếm ...) -';
    @Input() nzAllowClear?: boolean = true;
    @Input() maxOptionRender = 20;
    @Input() maxTagCount = 5;
    @Input() isAdd = false;
    @Input() isUpdate = false;
    @Input() isSearchAdvance = true;
    @Input() isCache = false;

    @Input() coSoId;
    @Input() pId;
    @Input() hinhThucToChucId: number;
    
    //Custom
    @Input() maTinh;
    @Input() maHuyen;
    @Input() maXa;
    @Input() arrLevel;
    @Input() isAMR;
    @Input() isHAI;

    @Output() eventChange = new EventEmitter<any>();

    search$ = new Subject<string>();
    nzMode: NzSelectModeType = 'default';
    _value: any | string | number = '';
    public optionList: any[] = [];
    public optionListDefault: any[] = [];
    _isDisabled = false;
    loading = false;
    private searchSubscription: Subscription;
    private dataSubscription: Subscription;
    public onChange = (v: any) => { };
    private onTouched = () => { };
    key = "combo-data-co-so-default";
    cache = sessionStorage.getItem(this.key);
    isMulti = false;

    constructor(
        private _comBoServiceProxy: ComboServiceProxy,
        private _CoSoUXService: CoSoUXService) {
    }

    writeValue(obj: any, isEmitChange = false): void {
        this._value = obj;
        this.getDataFromSever(undefined, true, isEmitChange);
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
    }

    onFocus(event: any): void {
        this.onTouched();
    }

    get svalue() {
        if (this._value != null) {
            let selectedItem = this.optionListDefault.find((m) => m.value == this._value);
            if (selectedItem != null) {
                return selectedItem.displayText;
            }
        }
    }

    @Input()
    get disabled() {
        return this._isDisabled;
    }

    set disabled(v: boolean) {
        this._isDisabled = v;
    }

    ngOnInit() {
        this.searchSubscription = this.search$.pipe(
            debounceTime(1000),
            distinctUntilChanged()
        )
            .subscribe((result) => {
                if(result) {
                    this.getDataFromSever(result);
                } else {
                    if(this._value) {this.optionList = this.optionListDefault;}
                    else {
                        this.getDataFromSever();
                    }
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.maTinh) {
            this.maTinh = changes.maTinh.currentValue;
            this.getDataFromSever();
        }
        if (changes.maHuyen) {
            this.maHuyen = changes.maHuyen.currentValue;
            this.getDataFromSever();
        }
        if (changes.maXa) {
            this.maXa = changes.maXa.currentValue;
            this.getDataFromSever();
        }

        if (changes.pId) {
            this.pId = changes.pId.currentValue;
            this.getDataFromSever();
        }

        if (changes.arrLevel) {
            this.arrLevel = changes.arrLevel.currentValue;
            this.getDataFromSever();
        }
    }

    getDataFromSever(search?: string, isChangeListDefault: boolean = false, isEmitChange = false) {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
            this.dataSubscription = null;
        }

        let isUseCache = !this.hinhThucToChucId && !this.coSoId && !search && !this._value && this.isCache;
        if (isUseCache && this.cache) {
            this.optionList = JSON.parse(this.cache);
        } else {

            let input = new CoSoComboInputDto();
            input.hinhThucToChucId = this.hinhThucToChucId;
            input.maTinh = this.maTinh;
            input.maHuyen = this.maHuyen;
            input.maXa = this.maXa;
            input.maxResult = this.maxOptionRender;
            input.filter = search;
            input.pId = this.pId;
            input.arrLevel = this.arrLevel;
            input.isAMR = this.isAMR;
            input.isHAI = this.isHAI;
            if(!search) input.id = this._value;

            this.loading = true;
            this.dataSubscription = this._comBoServiceProxy.allCoSo(input)
            .pipe(finalize(() => { this.loading = false }))
            .subscribe(
                result => {
                    this.optionList = result;
                    if (isUseCache && !this.cache) {
                        sessionStorage.setItem(this.key, JSON.stringify(result));
                    }
                    if(isChangeListDefault) {
                        this.optionListDefault = result;
                    }

                    if(isEmitChange) {
                        this.onChangeValue(this._value);
                    }
                }
            )
        }
    }

    ngOnDestroy() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
            this.searchSubscription = null;
        }
    }

    openCreateOrEditModal(id: number) {
        let _item = Object.assign(new CoSoDto(), { id });
        this._CoSoUXService.openCreateOrEditModal({
            dataItem: _item,
            callBack: (result: CoSoDto) => {
                if (result) {
                    if (id === 0) {
                        this.optionList.push({
                            value: result.id,
                            displayText: result.tenCoSo,
                            fts: AppUtilityService.getFullTextSearch(result.tenCoSo),
                            data: result,
                        });
                    } else {
                        var index = this.optionList.findIndex((x) => x.value == id);
                        if (index !== -1) {
                            this.optionList[index] = Object.assign(
                                {},
                                {
                                    value: result.id,
                                    displayText: result.tenCoSo,
                                    fts: AppUtilityService.getFullTextSearch(result.tenCoSo),
                                    data: result,
                                },
                            );
                        }
                    }
                    this.writeValue(result.id);
                }
            },
        });
    }

    searchAdvance() {
        this._CoSoUXService.openSearchListModal({
            listOfSelectedValue: this.isMulti ? [...this._value] : [this._value],
            title: 'Tìm kiếm ',
            isMulti: this.isMulti,

            coSoId: this.coSoId,
            pId: this.pId,
            maTinh: this.maTinh,
            maHuyen: this.maHuyen,
            maXa: this.maXa,
            arrLevel: this.arrLevel,
            isAMR : this.isAMR,
            isHAI : this.isHAI,

            callBack: (result: CoSoDto[]) => {
                if (result != null && result.length > 0) {
                    if (this.isMulti) {
                        let listOfSelectedValueFromModal = result.map((x) => x.id);
                        this.writeValue(listOfSelectedValueFromModal, true);
                    } else {
                        let selectedValueFromModal = result[0].id;
                        this.writeValue(selectedValueFromModal, true);
                    }
                }
            },
        });
    }

    onChangeValue(event: any): void {
        this.onChange(event);
        let selectedItem = this.optionList.find((x) => x.value == event);
        if (selectedItem) {
            this.eventChange.emit(selectedItem.data);
        }
        else {
            this.eventChange.emit(null);
        }
        if(this.isCache === true) this.optionListDefault = this.optionList;
    }
}
