<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="[16,6]">
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">ID</label>
            <input nz-input [(ngModel)]="dataItem.id" [disabled]="true">

        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Whonet Abx Code</label>
            <input nz-input [(ngModel)]="dataItem.whoneT_ABX_CODE" [disabled]="isView">

        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Who Code</label>
            <input nz-input [(ngModel)]="dataItem.whO_CODE" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Din Code</label>
            <input nz-input [(ngModel)]="dataItem.diN_CODE" [disabled]="isView">
        </div>

        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Jac Code</label>
            <input nz-input [(ngModel)]="dataItem.jaC_CODE" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Eucast Code</label>
            <input nz-input [(ngModel)]="dataItem.eucasT_CODE" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">User Code</label>
            <input nz-input [(ngModel)]="dataItem.useR_CODE" [disabled]="isView">
    
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Antibiotic</label>
            <input nz-input [(ngModel)]="dataItem.antibiotic" [disabled]="isView">
    
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Guidelines</label>
            <input nz-input [(ngModel)]="dataItem.guidelines" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Antibotic Class</label>
            <input nz-input [(ngModel)]="dataItem.class" [disabled]="isView">
        </div>
    
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Clsi</label>
            <input nz-input [(ngModel)]="dataItem.clsi" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Eucast</label>
            <input nz-input [(ngModel)]="dataItem.eucast" [disabled]="isView">
    
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Sfm</label>
            <input nz-input [(ngModel)]="dataItem.sfm" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Srga</label>
            <input nz-input [(ngModel)]="dataItem.srga" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Bsac</label>
            <input nz-input [(ngModel)]="dataItem.bsac" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Din</label>
            <input nz-input [(ngModel)]="dataItem.din" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Neo</label>
            <input nz-input [(ngModel)]="dataItem.neo" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Afa</label>
            <input nz-input [(ngModel)]="dataItem.afa" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Abx Number</label>
            <input nz-input [(ngModel)]="dataItem.abX_NUMBER" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Potency</label>
            <input nz-input [(ngModel)]="dataItem.potency" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Atc Code</label>
            <input nz-input [(ngModel)]="dataItem.atC_CODE" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Prof Class</label>
            <input nz-input [(ngModel)]="dataItem.proF_CLASS" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Cia Category</label>
            <input nz-input [(ngModel)]="dataItem.ciA_CATEGORY" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Clsi Order</label>
            <input nz-input [(ngModel)]="dataItem.clsI_ORDER" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Eucast Order</label>
            <input nz-input [(ngModel)]="dataItem.eucasT_ORDER" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Human</label>
            <input nz-input [(ngModel)]="dataItem.human" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Veterinary</label>
            <input nz-input [(ngModel)]="dataItem.veterinary" [disabled]="isView">
    
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Animal Gp</label>
            <input nz-input [(ngModel)]="dataItem.animaL_GP" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Loinccomp</label>
            <input nz-input [(ngModel)]="dataItem.loinccomp" [disabled]="isView">
        </div>
    
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Loincgen</label>
            <input nz-input [(ngModel)]="dataItem.loincgen" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Loincdisk</label>
            <input nz-input [(ngModel)]="dataItem.loincdisk" [disabled]="isView">
    
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Loincmic</label>
            <input nz-input [(ngModel)]="dataItem.loincmic" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Loincetest</label>
            <input nz-input [(ngModel)]="dataItem.loincetest" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Loincslow</label>
            <input nz-input [(ngModel)]="dataItem.loincslow" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Loincafb</label>
            <input nz-input [(ngModel)]="dataItem.loincafb" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Loincsbt</label>
            <input nz-input [(ngModel)]="dataItem.loincsbt" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Loincmlc</label>
            <input nz-input [(ngModel)]="dataItem.loincmlc" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Date Entered</label>
            <ora-date-time-picker [(ngModel)]="dataItem.datE_ENTERED" [disabled]="isView">
            </ora-date-time-picker>
         
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Date Modified</label>
            <input nz-input [(ngModel)]="dataItem.datE_MODIFIED" [disabled]="isView">
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-500">Comments</label>
            <input nz-input [(ngModel)]="dataItem.comments" [disabled]="isView">
        </div>
    </div>
   

</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>Tiếp tục thêm</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon
            nzType="save"></i>Lưu</button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng</button>
</div>