import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { OnlyNumberAndSpaceDirective } from './directives/only-number-and-space-directive';
import { TextMaskModule } from 'angular2-text-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { OrdPwdInputComponent } from './form-controls/ord-pwd-input.component';
import { InputWithSearchIconComponent } from './form-controls/input-with-search-icon.component';
import { ResetSearchButtonComponent } from './form-controls/reset-search-button.component';
import { SearchButtonComponent } from './form-controls/search-button.component';
import { OrdSfComponent } from './ord-sf/ord-sf.component';
import { ShowFormControlPipe } from './pipes/show-form-control.pipe';
import { OfLabelComponent } from './components/of-label/of-label.component';
import { OfFormSwitchComponent } from './components/of-form-switch/of-form-switch.component';
import { ElementIdControlPipe } from './pipes/element-id-control.pipe';
import { OrdFooterFixedComponent } from './ord-footer-fixed/ord-footer-fixed.component';
import { TextAreaControlComponent } from './form-controls/base/text-area-control.component';
import { TextControlComponent } from './form-controls/base/text-control.component';
import { OnlyNumberControlComponent } from './form-controls/base/only-number-control.component';
import { SelectControlComponent } from './form-controls/base/select-control.component';
import { CheckBoxControlComponent } from './form-controls/base/check-box-control.component';
import { SwitchControlComponent } from './form-controls/base/switch-control.component';
import { RadioControlComponent } from './form-controls/base/radio-control.component';
import { CurrencyControlComponent } from './form-controls/base/currency-control.component';
import { NumberControlComponent } from './form-controls/base/number-control.component';
import { PassWordControlComponent } from './form-controls/base/pass-word-control.component';
import { FileControlComponent } from './form-controls/base/file-control.component';
import { ValidateDynamicFormComponent } from './dynamic-form/validate-dynamic-form.component';
import { DynamicFormPageComponent } from './dynamic-form/dynamic-form-page.component';
import { FormBuilderModule } from '../form-builder/form-builder.module';
import { SHARED_ZORRO_MODULES } from '@shared/shared-zorro.module';


const BaseControl = [
    TextAreaControlComponent,
    TextControlComponent,
    OnlyNumberControlComponent,
    SelectControlComponent,
    CheckBoxControlComponent,
    SwitchControlComponent,
    RadioControlComponent,
    CurrencyControlComponent,
    NumberControlComponent,
    PassWordControlComponent,
    FileControlComponent
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ...SHARED_ZORRO_MODULES,
        TextMaskModule,
        CurrencyMaskModule,
        FormBuilderModule,
        //TranslateModule
    ],
    declarations: [
        DynamicFormPageComponent,
        //OnlyNumberDirective,
        OnlyNumberAndSpaceDirective,
        OrdPwdInputComponent,
        InputWithSearchIconComponent,
        SearchButtonComponent,
        ResetSearchButtonComponent,
        BaseControl,
        ValidateDynamicFormComponent,
        OrdSfComponent,
        ShowFormControlPipe,
        OfLabelComponent,
        OfFormSwitchComponent,
        ElementIdControlPipe,
        OrdFooterFixedComponent,
    ],
    exports: [
        DynamicFormPageComponent,
        OrdSfComponent,
        OrdPwdInputComponent,
        OrdFooterFixedComponent,
        BaseControl,
    ]
})
export class OrdFormCustomModule {
}
