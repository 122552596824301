<div class="oda-body-main" [id]="formId">
    <div nz-row [nzGutter]="[12,6]">
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">{{'_TenTiengAnh' | localize}}<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.name_en" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.name_en"></validation-custom>
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">{{'_TenTiengViet' | localize}}<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.name_vi" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.name_en"></validation-custom>
        </div>
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-normal">{{'_Code' | localize}}<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.code_en" [disabled]="isView">
            <validation-custom *ngIf="!isView" [sModel]="dataItem.code_en"></validation-custom>
        </div>
       
        
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-normal">{{'_PhanLoai' | localize}}</label>
            <enum-combo [EnumCode]="ComboBoxEnumCode.PHAN_LOAI_BENH_PHAM" [(ngModel)]="dataItem.spec_group" [disabled]="isView">
            </enum-combo>
            
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-normal"></label> 
            <div style="margin-top: 10px;">
                <label nz-checkbox [(ngModel)]="isHuman"  [disabled]="isView">{{'_TrenNguoi' | localize}}</label> 
            </div>           
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="6" nzXl="6">
            <label class="font-weight-normal"></label> 
            <div style="margin-top: 10px;">
                <label nz-checkbox [(ngModel)]="isAnimal"  [disabled]="isView">{{'_TrenDongVat' | localize}}</label> 
            </div>           
        </div>
        
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">{{'_NhomBenhPham' | localize}}</label>
            <table-combo [TableEnum]="ComboBoxTableCode.GetAllNhomBenhPham" 
            [(ngModel)]="dataItem.specimen_group_id" [disabled]="isView"   (eventChange)="changePhanNhomChinhKhac($event)"  >
            </table-combo>
            <input style="display: none;" nz-input [(ngModel)]="dataItem.group_name_vi" [disabled]="isView">
          
        </div>
    
        <div nz-col  nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
            <label class="font-weight-normal">{{'_GhiChu' | localize}}</label>
            <input nz-input [(ngModel)]="dataItem.note" [disabled]="isView">
         
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <label class="font-weight-normal">{{'_TrangThai' | localize}}<span class="text-red">*</span></label>
            <nz-select [(ngModel)]="dataItem.activated" [disabled]="isView" style="width: 100%;" nzPlaceHolder="-Chọn-">
                <nz-option [nzValue]="true" nzLabel="{{'_DangHoatDong' | localize}}"></nz-option>
                <nz-option [nzValue]="false" nzLabel="{{'_DungHoatDong' | localize}}"></nz-option>
            </nz-select>
            <validation-custom *ngIf="!isView" [sModel]="dataItem.activated"></validation-custom>
        </div>
    </div>

</div>
<div *nzModalFooter>
    <label *ngIf="!dataItem.id" nz-checkbox [(ngModel)]="isContinue"><b>{{'_TiepTucThem' | localize}}</b></label>
    <button *ngIf="!isView" type="button" nz-button nzType="primary" (click)="save()"><i nz-icon nzType="save"></i>{{'_Luu' | localize}}</button>
    <button type="button" nz-button nzType="default" (click)="close()">{{'Close' | localize}}</button>
</div>
