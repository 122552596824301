<ng-container [ngSwitch]="ordSize" *ngIf="totalCount > 0; else khongDuLieuTpl">
    <ng-container *ngSwitchCase="'default'">
        <div class="pagination">
            <div class="mr-1">
                <ng-content select="[actionTable]"></ng-content>
            </div>
            <div class="d-flex">
                <ng-container [ngTemplateOutlet]="selectPageSizeTpl"></ng-container>
                <ng-container [ngTemplateOutlet]="dangXemTpl"></ng-container>
                <ng-container [ngTemplateOutlet]="btnChuyenTrangSimpleTpl"></ng-container>
            </div>

        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'sm'">
        <div nz-row class="form-group mt-3">
            <div nz-col nzSpan="24">
                <ng-container [ngTemplateOutlet]="btnChuyenTrangTpl"></ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'md'">
        <div nz-row class="form-group mt-3">
            <div nz-col nzFlex="5">
                <ng-container [ngTemplateOutlet]="btnChuyenTrangTpl"></ng-container>
            </div>
            <div nz-col nzFlex="3">
                <ng-container [ngTemplateOutlet]="dangXemTpl"></ng-container>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #khongDuLieuTpl>
    <span>{{'_KhongCoBanGhiNao' | localize}}!</span>
</ng-template>

<ng-template #btnChuyenTrangTpl>
    <nz-button-group>
        <button nz-button nzType="default" title="{{'_TrangDau' | localize}}" (click)="trangDauClick()" [disabled]="disableBackBtn()">
            <i nz-icon nzType="step-backward"></i>
        </button>
        <button nz-button nzType="default" title="{{'_TrangTruoc' | localize}}" (click)="trangTruocClick()" [disabled]="disableBackBtn()">
            <i nz-icon nzType="caret-left"></i>
        </button>
    </nz-button-group>
    <span class="font-weight-bolder ml-10 mr-5">{{'_Trang' | localize}}</span>
    <input nz-input [OnlyNumber]="true" style="width: 68px" (keydown)="keyDownInputPageIndex($event)" [(ngModel)]="pageIndex" (keyup.enter)="pageIndexEnter()"
        (focusout)="onFocusOutPageIndexInput()" nz-tooltip title="{{'_NhapSoTrang_AnEnter' | localize}}" nzTooltipPlacement="bottom" />
    <span class="font-weight-bolder mr-10"> / {{ getTotalPage() }}</span>
    <nz-button-group>
        <button nz-button nzType="default" title="{{'_TrangSau' | localize}}" (click)="trangSauClick()" [disabled]="disableNextBtn()">
            <i nz-icon nzType="caret-right"></i>
        </button>
        <button nz-button nzType="default" title="{{'_TrangCuoi' | localize}}" (click)="trangCuoiClick()" [disabled]="disableNextBtn()">
            <i nz-icon nzType="step-forward"></i>
        </button>
    </nz-button-group>
</ng-template>
<ng-template #btnChuyenTrangSimpleTpl>
    <button nz-button nzType="text" title="{{'_TrangTruoc' | localize}}" (click)="trangTruocClick()" [disabled]="disableBackBtn()">
        <i nz-icon nzType="caret-left"></i>
    </button>
    <button nz-button nzType="text" title="{{'_TrangSau' | localize}}" (click)="trangSauClick()" [disabled]="disableNextBtn()">
        <i nz-icon nzType="caret-right"></i>
    </button>
</ng-template>
<ng-template #btnReloadTpl>
    <button nz-button nzType="dashed" class="ml-10" nz-tooltip title="{{'_LayLaiDuLieu' | localize}}" (click)="refreshData.emit(1)">
        <i nz-icon nzType="redo" nzTheme="outline"></i>
    </button>
</ng-template>
<ng-template #selectPageSizeTpl>
    <nz-select [(ngModel)]="pageSize" (ngModelChange)="onChangePageSize()">
        <nz-option *ngFor="let pz of listPageSizeOptionsSelect" [nzValue]="pz.value" [nzLabel]="pz.display"></nz-option>
    </nz-select>
</ng-template>
<ng-template #dangXemTpl>
    <div style="align-self: center" class="font-weight-bolder ml-2">{{ getDangXemTxt() }}</div>
</ng-template>
