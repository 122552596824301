import { Component, Injector, Input, OnInit } from "@angular/core";
import { AppUtilityService } from "@app/shared/common/custom/utility.service";
import { ModalComponentBase } from "@shared/common/modal-component-base";
import { AMR_GenusServiceProxy,  WHONET_OrganismsDto, WHONET_OrganismsServiceProxy, ComboBoxEnumCode, ComboBoxTableCode, TRANG_THAI_AMR } from "@shared/service-proxies/service-proxies";
import * as $ from 'jquery';
import { finalize } from "rxjs/operators";
@Component({
    selector: 'whonet-organisms-edit-modal',
    templateUrl: './whonet-organisms-edit-modal-component.html',
})
export class OrganismsEditComponent extends ModalComponentBase implements OnInit {
    formId = 'formWhonet';
    @Input() dataItem: WHONET_OrganismsDto;
    @Input() isView = false;
    TRANG_THAI_AMR = TRANG_THAI_AMR;
    ComboBoxEnumCode = ComboBoxEnumCode;
    ComboBoxTableCode = ComboBoxTableCode;
    
    constructor(
        injector: Injector,
        private _dataService: WHONET_OrganismsServiceProxy,       
        ) {
        super(injector);
    }

    ngOnInit(): void {
        if (this.dataItem.id > 0) {
            this.getInfoById(this.dataItem.id);
        } else { 
            this.clearForm();
        }
    }

    getInfoById(id): void {
        abp.ui.setBusy();
        this._dataService
            .getById(id)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((response) => {
                this.dataItem = response;
            });
    }

    save(){
        if (AppUtilityService.IsNullValidateForm(this.formId)) {
            abp.notify.error('Bạn cần nhập đủ dữ liệu các trường có dấu * đỏ !!');
            return;
        }
        abp.ui.setBusy();
        this._dataService
            .createOrUpdate(this.dataItem)
            .pipe(
                finalize(() => {
                    abp.ui.clearBusy();
                }),
            )
            .subscribe((result) => {
                if (result.isSuccessful) {
                    this.notify.success(this.l('SavedSuccessfully'));
                    if (this.isContinue) {
                        this.clearForm();
                    } else {
                        this.success(result.dataResult);
                    }
                } else {
                    this.notify.error(result.errorMessage);
                }
            });
    }


    clearForm() {
        this.dataItem = Object.assign(new WHONET_OrganismsDto());
    }

    //#region UX_JQuery

    fullScreenClick() {
        let idEle = '.full-screen';
        var iCheck = true;
        $(idEle).click(function () {
            if (iCheck) {
                $('.ng-trigger-modalContainer').addClass('ant-hidden');
                $('.ant-modal').addClass('ant-modal-full');
            } else {
                $('.ng-trigger-modalContainer').removeClass('ant-hidden');
                $('.ant-modal').removeClass('ant-modal-full');
            }

            iCheck = !iCheck;
        });
    }

    dropDrapModal() {
        let modalContent: any = $('.ant-modal-content');
        modalContent.draggable({
            handle: '.ant-modal-header',
        });
    }

    ngAfterViewInit(): void {
        this.dropDrapModal();
        this.fullScreenClick();
    }
    
}
