import { Component, EventEmitter, forwardRef, Injector, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppUtilityService } from '@app/shared/common/custom/utility.service';
import { ComboBoxDto, CommonServiceProxy } from '@shared/service-proxies/service-proxies';
import { AbpSessionService } from 'abp-ng2-module';
import * as _ from 'lodash';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { BaseComboComponent } from './base-combo-abstract';
import { RegisterServicesKeyUX } from './register-service-key-ux';
import { finalize } from 'rxjs/operators';
import { LocalizationService } from 'abp-ng2-module';

@Component({
    selector: 'table-combo',
    template: `
      <div class="d-flex ">
        <nz-select
                #selectOption
                [(ngModel)]="_value"
                nzShowSearch
                nzServerSearch
                [nzAllowClear]="AllowClear"
                nzPlaceHolder="{{ placeHolder }}"
                [nzMode]="nzMode"
                [nzMaxTagCount]="nzMaxTagCount"
                [nzDisabled]="_isDisabled"
                (nzFocus)="(onFocus)"
                style="width:100%"
                (ngModelChange)="onChangeValue($event)"
                (nzOnSearch)="search($event)"
                [nzDropdownRender]="renderTemplate"
                [nzLoading]="loading"
            >
                <nz-option *ngFor="let item of optionList" [nzLabel]="item.displayText" [nzValue]="item.value"></nz-option>
            </nz-select>
            <button nz-button nzType="text" (click)="openCreateOrEditModal(_value)" *ngIf="isUpdate && _value && !disabled">
                <i nz-icon nzType="edit"></i>
            </button>
            <button nz-button nzType="text" (click)="searchAdvance()" *ngIf="isView && _value && !disabled">
                <i nz-icon nzType="eye"></i>
            </button>
            <ng-template #renderTemplate>
                <hr *ngIf="isAdd || isSearchAdvance" style="margin: 6px 0px" />
                <div style="display: flex; justify-content: space-between;">
                    <div *ngIf="isSearchAdvance">
                        <a nz-button nzType="link" (click)="searchAdvance()">
                            <i nz-icon nzType="search" nzTheme="outline"></i> {{'_TimKiem' | localize}}
                        </a>
                    </div>
                    <div>
                        <a *ngIf="isReloadData" nz-button nzType="link" (click)="reloadData()">
                            <i nz-icon nzType="sync" nzTheme="outline"></i>Tải lại
                        </a>
                        <a *ngIf="isAdd" nz-button nzType="link" (click)="openCreateOrEditModal(0)">
                            <i nz-icon nzType="plus-circle" nzTheme="outline"></i>{{'_ThemMoi' | localize}}
                        </a>
                        
                    </div>
                </div>
                
            </ng-template>
        </div>
    `,
    styles: [
        `
            .ant-select,
            .ant-select-multiple .ant-select-selector {
                height: auto !important;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TableComboComponent),
            multi: true,
        },
    ],
})
export class TableComboComponent extends BaseComboComponent implements OnInit, OnChanges, ControlValueAccessor {
    @ViewChild('selectOption', { static: true }) selectOption;
    @Input() TableEnum: number;
    @Input() CascaderId?: number;
    @Input() typeRequest?: number;
    @Input() IgnoreListId: number[];
    @Input() nzMaxTagCount: any = 1;
    @Input() isDefaultFirstRecord?: boolean = false;
    @Input() isDefaultActiveRecord?: boolean = false;
    @Input() DefaultCode: string = '';
    @Input() IsNumberValue?: boolean = false;
    @Input() AllowClear?: boolean = true;
    @Input() isAdd = false;
    @Input() isUpdate = false;
    @Input() isView = false;
    @Input() isSearchAdvance = false;
    @Input() isReloadData = false;
    @Input() tenantIdInput: number;
    @Output() eventChange = new EventEmitter();
    loading = false;


    constructor(
        injector: Injector,
        private localization: LocalizationService,
        private registerServices: RegisterServicesKeyUX,
        private _sessionService: AbpSessionService,
        private _dataService: CommonServiceProxy) {
        super(injector);
    }

    ngOnInit() {
        if (this.isMulti) {
            this.nzMode = 'multiple';
        }
        if (AppUtilityService.isNullOrEmpty(this.placeHolder)) {
            this.placeHolder = this.l('_Chon');
        }
        if (!this.CascaderId)
            this.getDataSourceFromServer();

        //get service register
        this.serviceUX = this.registerServices.getService(this.TableEnum);
    }

    ngAfterViewInit() { }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ArrCodeSkiped) {
            this.getDataSourceFromServer();
        }
        if (changes.CascaderId) {
            this.CascaderId = changes.CascaderId.currentValue;
            this.getDataSourceFromServer();
        }
        if (changes.typeRequest) {
            this.getDataSourceFromServer();
        }
    }

    getDataSourceFromServer() {
        let req: any = {
            tableEnum: this.TableEnum,
            cascaderId: this.CascaderId,
            type: this.typeRequest,
            tenantId: this.tenantIdInput
        };
        let tenantId = this._sessionService.tenantId ?? 0;
        let cascaderId = this.CascaderId ?? 0;
        const key = 'combo-data-' + this.TableEnum + '-tenant-' + tenantId + "-" + cascaderId + "-" + (this.typeRequest ?? 0);
        const cache = sessionStorage.getItem(key);
        if (cache && this.isUseCache) {
            let lst = JSON.parse(cache);

            if (this.IgnoreListId != null) {
                if (this.IgnoreListId.length > 0) {
                    this.IgnoreListId.forEach((item) => {
                        lst = lst.filter((p) => p.value != item);
                    });
                }
            }
            this.setListOfOption(lst);

            //set value default
            this.getValueDefault(lst);

            return;
        }
        req.languageCode = this.localization.currentLanguage?.name;

        this.loading = true;
        this._dataService.getDataTableCombo(req)
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((d) => {
                let lst = _.map(d, (it) => {
                    return Object.assign(
                        {
                            ...it,
                        },
                        {
                            value: this.IsNumberValue ? parseInt(it.value) : (it.value as string),
                            displayText: AppUtilityService.isNullOrEmpty(it.displayText) ? '' : it.displayText,
                            data: it.data,
                            fts: AppUtilityService.getFullTextSearch(AppUtilityService.isNullOrEmpty(it.displayText) ? '' : it.displayText),
                        },
                    );
                });
                if (this.isUseCache) {
                    sessionStorage.setItem(key, JSON.stringify(lst));
                }

                if (this.IgnoreListId != null) {
                    if (this.IgnoreListId.length > 0) {
                        this.IgnoreListId.forEach((item) => {
                            lst = lst.filter((p) => p.value != item);
                        });
                    }
                }

                this.setListOfOption(lst);
                //set value default
                this.getValueDefault(lst);

                this.addSelectedNotInRender();
            });
    }

    getValueDefault(lst: any[]) {
        setTimeout(() => {
            if (!AppUtilityService.isNullOrEmpty(this.DefaultCode) && AppUtilityService.isNullOrEmpty(this._value)) {
                let obj = lst.find((m) => m.data.code == this.DefaultCode);
                if (obj) {
                    this._value = obj.value;
                    this.onChangeValue(obj.value);
                }
            }

            if (AppUtilityService.isNullOrEmpty(this._value)) {

                if (this.isDefaultFirstRecord == true) {
                    let obj = lst[0];
                    if (obj) {
                        this._value = obj.value;
                        this.onChangeValue(obj.value);
                    }
                }

                if (this.isDefaultActiveRecord == true) {
                    let active = lst.find(item => {
                        return item.isActive == true;
                    });

                    if (active) {
                        this._value = active.value;
                        this.onChangeValue(active.value);
                    }
                }
            }

            if (this.isMulti != true) {
                if (!this.optionListSource.some((p) => p.value == this._value)) {
                    this._value = null;
                    this.setValueChange(this._value);
                }
            }

        }, 50);
    }

    setValueChange(event) {
        this._value = event;
        this.onChangeValue(event);
    }

    onChangeValue(event) {
        this.onChange(event);
        if (this.isMulti != true) {
            let obj = this.optionList.find((m) => m.value == event);
            this.eventChange.emit(obj);
        } else {
            if (this._value != null) {
                let ListChosen = this.optionList.filter((m) => this._value.includes(m.value));
                this.eventChange.emit(ListChosen);
            } else {
                this.eventChange.emit([]);
            }
        }
    }

    openCreateOrEditModal(id: number) {
        let obj = this.optionListSource.find(p => p.value == this._value);
        if (id == 0) {
            obj = null;
        }
        let _item = Object.assign({ id }, {
            ...obj?.data
        });

        this.serviceUX.openCreateOrEditModal({
            dataItem: _item,
            isFromControl: true,
            isView: this.isView,
            cascaderId: this.CascaderId,
            type: this.typeRequest,
            callBack: (result: NzSafeAny) => {
                if (result) {
                    var obj = new ComboBoxDto();
                    obj.value = result.id;
                    obj.displayText = result.name;
                    obj.data = result;

                    if (id === 0) {
                        this.optionList.push(obj);
                        this.optionListSource.push(obj);
                        this.onChangeValue(obj.value);
                    } else {
                        var index = this.optionListSource.findIndex((x) => x.value == obj.value);
                        if (index !== -1) {
                            this.optionListSource[index] = Object.assign(
                                {},
                                obj
                            );
                        }
                    }

                    this.setListOfOption(this.optionListSource);
                    this.writeValue(obj.value);
                    this.addSelectedNotInRender();

                }
            },
        });
    }

    searchAdvance(id?: number) {
        let obj = this.optionListSource.find(p => p.value == this._value);
        let _item = Object.assign({ id }, {
            ...obj?.data
        });

        this.serviceUX.openSearchListModal({
            listOfSelectedValue: this.isMulti ? [...this._value] : [this._value],
            title: this.placeHolder,
            isMulti: this.isMulti,
            dataItem: _item,
            isModal: true,
            callBack: (result: NzSafeAny[]) => {
                if (result != null && result.length > 0) {
                    if (this.isMulti) {
                        let listOfSelectedValueFromModal = result.map((x) => x.value);
                        this.writeValue(listOfSelectedValueFromModal);
                    } else {
                        let selectedValueFromModal = result[0].value;
                        this.writeValue(selectedValueFromModal);
                        this.addSelectedNotInRender();
                    }
                }
            },
        });
    }

    reloadData() {
        this.getDataSourceFromServer();
    }
}
