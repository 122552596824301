import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzOutletModule } from '@node_modules/ng-zorro-antd/core/outlet';
import { NzPaginationModule } from '@node_modules/ng-zorro-antd/pagination';
import { NzTableModule } from '@node_modules/ng-zorro-antd/table';
import { OraCellDirective } from './directives/ora-cell.directive';
import { OrdPaginationComponent } from './ord-pagination/ord-pagination.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule } from '@angular/forms';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { OraTableComponent } from './table.component';
import { OraColumnDirective } from './directives/ora-column.directive';
import { OraHeaderDirective } from './directives/ora-header.directive';

import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { OnlyNumberDirective } from '../directives/only-number-directive';
import { LocalizePipe } from './localize.pipe';

const COM_EXPORT = [
    OraTableComponent,
    OraColumnDirective,
    OraCellDirective,
    OraHeaderDirective,
    OrdPaginationComponent];

const DIRECTIVES = [
    OnlyNumberDirective,
    LocalizePipe
]

@NgModule({
    declarations: [...COM_EXPORT, DIRECTIVES],
    exports: [...COM_EXPORT, DIRECTIVES],
    imports: [
        FormsModule,
        CommonModule,
        NzTableModule,
        NzOutletModule,
        NzPaginationModule,
        NzButtonModule,
        NzSelectModule,
        NzIconModule,
        NzInputModule,
        NzGridModule,
        NzEmptyModule,
    ],
})
export class OraTableModule { }
