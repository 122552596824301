<div [id]="formId">
    <div nz-row [nzGutter]="12">
        <div nz-col class="gutter-row input-add" nzXs="24" nzMd="24">
            <label class="font-weight-normal">Cơ sở<span class="text-red">*</span></label>
            <co-so-combo [(ngModel)]="dataItem.coSoId" [coSoId]="coSoId" [disabled]="coSoId > 0"></co-so-combo>
            <validation-custom [sModel]="dataItem.coSoId"></validation-custom>
        </div>
        <div nz-col class="gutter-row input-add" nzXs="24" nzMd="12">
            <label class="font-weight-normal">Tên phòng<span class="text-red">*</span></label>
            <input nz-input [(ngModel)]="dataItem.tenKhoaPhong" [disabled]="isView" />
            <validation-custom [sModel]="dataItem.tenKhoaPhong"></validation-custom>
        </div>
        <div nz-col class="gutter-row input-add" nzXs="24" nzMd="12">
            <label class="font-weight-normal">Loại khoa phòng<span class="text-red">*</span> </label>
            <enum-combo [EnumCode]="ComboBoxEnumCode.LoaiKhoaPhong" [(ngModel)]="dataItem.loaiKhoaPhong"
                        [disabled]="isView">
            </enum-combo>
            <validation-custom [sModel]="dataItem.loaiKhoaPhong"></validation-custom>
        </div>
        <div nz-col class="gutter-row input-add" nzXs="24" nzMd="12">
            <label class="font-weight-normal">Tổng số cán bộ</label>
            <nz-input-number [(ngModel)]="dataItem.tongSoCanBo" type="number" [nzDisabled]="isView"></nz-input-number>

        </div>
        <div nz-col class="gutter-row input-add" nzXs="24" nzMd="12">
            <label class="font-weight-normal">Tổng số bác sĩ</label>
            <nz-input-number [(ngModel)]="dataItem.tongSoBacSi" type="number" [nzDisabled]="isView"></nz-input-number>
        </div>
        <div nz-col class="gutter-row input-add" nzXs="24" nzMd="12">
            <label class="font-weight-normal">Tổng số điều dưỡng/hộ sinh</label>
            <nz-input-number [(ngModel)]="dataItem.tongSoDieuDuongHoSinh" type="number"
                             [nzDisabled]="isView"></nz-input-number>
        </div>
        <div nz-col class="gutter-row input-add" nzXs="24" nzMd="12">
            <label class="font-weight-normal">Tổng số dược sĩ</label>
            <nz-input-number [(ngModel)]="dataItem.tongSoDuocSi" type="number" [nzDisabled]="isView"></nz-input-number>
        </div>
        <div nz-col class="gutter-row input-add" nzXs="24" nzMd="12">
            <label class="font-weight-normal">Tổng số giường bệnh KH</label>
            <nz-input-number [(ngModel)]="dataItem.tongSoGiuongBenhKH" type="number"
                             [nzDisabled]="isView"></nz-input-number>
        </div>
        <div nz-col class="gutter-row input-add" nzXs="24" nzMd="12">
            <label class="font-weight-normal">Tổng số giường bệnh TT</label>
            <nz-input-number [(ngModel)]="dataItem.tongSoGiuongBenhTT" type="number"
                             [nzDisabled]="isView"></nz-input-number>
        </div>
        <div class="gutter-row" style="display: flex; align-items: flex-end;" nz-col nzXs="24" nzMd="12">
            <label nz-checkbox [disabled]="isView" [(ngModel)]="dataItem.isCongKhai" class="mr-2"><b>Công khai y tế</b></label>
            <label nz-checkbox [disabled]="isView" [(ngModel)]="dataItem.isPhongXetNghiem"><b>Là phòng xét nghiệm</b></label>
        </div>
    </div>
</div>


<div *nzModalFooter>
    <button type="button" nz-button nzType="primary" (click)="save()" *ngIf="!isView">
        <i nz-icon nzType="save"></i>Lưu (F10)
    </button>
    <button type="button" nz-button nzType="default" (click)="close()">Đóng (ESC)</button>
</div>
